export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Estado de dominios',
    route: 'page-statuses',
    icon: 'BarChart2Icon',
  },
  {
    header: 'Banners y Popups',
  },
  {
    title: 'Contenido',
    route: 'banner',
    icon: 'FileTextIcon',
  },
  {
    title: 'Categorias',
    route: 'categories',
    icon: 'BookIcon',
  },
  {
    title: 'Paises',
    route: 'countries',
    icon: 'MapIcon',
  },
  {
    title: 'QR',
    route: 'content-qr',
    icon: 'GridIcon',
  },
  {
    title: 'Idiomas',
    route: 'langs',
    icon: 'GlobeIcon',
  },
  {
    header: 'Módulos',
  },
  {
    title: 'Cajeros',
    route: 'cashiers',
    icon: 'CreditCardIcon',
  },
  {
    title: 'Monedas',
    route: 'currencies',
    icon: 'DollarSignIcon',
  },
  {
    title: 'Torneos',
    route: 'tournaments',
    icon: 'DatabaseIcon',
  },
  {
    title: 'Link Promociones',
    route: 'link-promotions',
    icon: 'LinkIcon',
  },
  {
    title: 'Header',
    route: 'header',
    icon: 'MenuIcon',
  },
  {
    title: 'Dominios',
    route: 'domains',
    icon: 'Link2Icon',
  },
  {
    header: 'Sorteo',
  },
  {
    title: 'Sorteos',
    route: 'lottery',
    icon: 'AwardIcon',
  },
  {
    title: 'Participantes',
    route: 'participants',
    icon: 'UsersIcon',
  },
  {
    title: 'Comercios',
    route: 'commerce',
    icon: 'InboxIcon',
  },
  {
    title: 'Categorías',
    route: 'categories-award',
    icon: 'BookIcon',
  },
  {
    header: 'Usuarios Web',
  },
  {
    title: 'Clientes',
    route: 'web-clients',
    icon: 'UserIcon',
  },
  {
    header: 'Usuarios ',
  },
  {
    title: 'Usuarios',
    route: 'users',
    icon: 'UserIcon',
  },
  // {
  //   header: 'Próximamente',
  // },
  // {
  //   title: 'Ocultar elementos',
  //   route: 'hide-elements',
  //   icon: 'EyeOffIcon',
  // },
  // {
  //   title: 'Reto',
  //   route: 'challenge',
  //   icon: 'AwardIcon',
  // },

  // {
  //   header: 'Account',
  // },
  // {
  //   title: 'Configuraciones',
  //   route: 'account-setting',
  //   icon: 'SettingsIcon',
  // },
  // {
  //   title: 'Reset Password',
  //   route: 'auth-reset-password',
  //   target: '_blank',
  // },

  // {
  //   title: 'Miscellaneous',
  //   icon: 'FileIcon',
  //   children: [
  //     {
  //       title: 'Coming Soon',
  //       route: 'misc-coming-soon',
  //       // target: '_blank',
  //     },
  //     {
  //       title: 'Not Authorized',
  //       route: 'misc-not-authorized',
  //       // target: '_blank',
  //     },
  //     {
  //       title: 'Under Maintenance',
  //       route: 'misc-under-maintenance',
  //       // target: '_blank',
  //     },
  //     {
  //       title: 'Error',
  //       route: 'misc-error',
  //       // target: '_blank',
  //     },
  //   ],
  // },
]
